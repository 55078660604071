<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div
    v-if="isLoading"
    class="progress-indicator"
  />
</template>

<style>
.progress-indicator {
  position: relative;
  margin: 50px 0;
  background-color: rgba(255,255,255,.1);
}
.progress-indicator:after {
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  border-color: var(--color-grey400) transparent transparent transparent;
  border-width: 8px;
  border-style: solid;
  border-radius: 50%;
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
