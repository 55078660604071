import { query, request } from '@distancify/drapejs-litium';
import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { gql } from 'graphql-request';

export const commands = {
  executeLitiumCallback: defineCommand<{
    path: string;
  }>('executeLitiumCallback'),
  getReceipt: defineCommand<{
    url: string;
    orderId: string;
  }>('getReceipt'),
};

export const receivers = {
  executeLitiumCallback: defineReceiver(commands.executeLitiumCallback, function (command) {
    return litiumFetch(command.path, this.cache);
  }, 'litium'),
  getReceipt: defineReceiver(commands.getReceipt, async function (command, data) {
    const response = await request(this.cache,
      query(gql`
        query getReceipt($url: String!, $orderId: String!) {
          session(url: $url) {
            receipt(orderId: $orderId) {
              totalDeliveryCost
              totalDiscount
              totalOrderRow
              totalVat
              grandTotal
              paymentMethod
              externalOrderId
              personId
              consent
              customerNumber
              customerEmail
              campaignInfo
              overallVatPercentage
              totalOrderRowsFormatted
              totalOrderDiscountFormatted
              totalDeliveryFormatted
              totalVatFormatted
              grandTotalFormatted
              isSplitDelivery
              rows {
                id
                articleNumber
                variantName
                baseProductName
                quantity
                unitListPrice
                unitPrice
                campaignName
                imageId
                url
                totalPrice
                isModelImage
                priceFormatted
                unitPriceFormatted
                unitListPriceFormatted
                color
                size
                baseProductId
                shippingInterval {
                  inventoryId
                  minimumDeliveryTimeInDays
                  maximumDeliveryTimeInDays
                }
              }
              currency {
                id
              }
            }
          }
        }
      `),
      {
        url: command.url,
        orderId: command.orderId,
      }
    );

    return response.session.receipt;
  }, 'litium'),
};

async function litiumFetch(path, cache, options = {} as any) {
  if (!options.headers) {
    options.headers = {};
  }
  const litium = (await cache.getItem('__litium')) || {} as any;

  if (litium.session) {
    options.headers['LH-Session'] = litium.session;
  } else {
    options.headers['LH-Session'] = '*';
  }
  if (litium.cart) {
    options.headers['LH-Cart'] = litium.cart;
  }
  if (litium.uid) {
    options.headers['LH-Uid'] = litium.uid;
  }

  const res = await fetch(`${path}`, options);
  return res.json();
}
